<template>
  <div>
    <b-row>
      <b-col cols="12">
        <b-overlay
          :show="$apollo.loading || mutationLoading"
          spinner-variant="primary"
        >
          <b-card title="All Incubators">
            <!-- search input -->
            <div class="custom-search d-flex justify-content-between">
              <b-form-group>
                <div class="d-flex align-items-center">
                  <label class="mr-1">Search</label>
                  <b-form-input
                    v-model="searchTerm"
                    class="d-inline-block mr-1"
                    placeholder="Search Incubators"
                    type="text"
                  />
                </div>
              </b-form-group>
              <b-form-group>
                <b-form-radio-group
                  id="btn-radios-1"
                  v-model="association"
                  :options="[
                    { text: 'All', value: null },
                    { text: 'Associated', value: 'associated' },
                    {text:'Unassociated',value:'unassociated'},
                  ]"
                  button-variant="light"
                  buttons
                  size="sm"
                />
              </b-form-group>
            </div>

            <!-- table -->
            <vue-good-table
              :columns="columns"
              :pagination-options="{
                enabled: true,
                perPage:pageLength
              }"
              :rows="filterIncubators"
              :search-options="{
                enabled: true,
                externalQuery: searchTerm }"
            >
              <template
                slot="table-row"
                slot-scope="props"
              >

                <span v-if="props.column.field === 'buttons'">
                  <b-button
                    variant="outline-primary"
                    size="sm"
                    @click="selectedRow = props.row.originalIndex; $bvModal.show('view-incubator-modal')"
                  >View Details</b-button>
                  <b-button
                    v-if="props.row.usersAssociationtablesByAssociatedOrg && props.row.usersAssociationtablesByAssociatedOrg.length === 0"
                    variant="outline-success"
                    class="ml-50"
                    size="sm"
                    @click="joinIncubator(props.row.id)"
                  >Associate</b-button>
                  <b-button
                    v-else-if="userRole === 'user'"
                    :to="{ name: 'startup-incubator-manager', params: { id: props.row.usersAssociationtablesByAssociatedOrg[0].id } }"
                    variant="outline-primary"
                    class="ml-50"
                    size="sm"
                  >Incubator Manager</b-button>
                  <b-button
                    v-else
                    :to="{ name: 'student-incubator-manager', params: { id: props.row.usersAssociationtablesByAssociatedOrg[0].id } }"
                    variant="outline-primary"
                    class="ml-50"
                    size="sm"
                  >Incubator Manager</b-button>
                </span>

                <!-- Column: Common -->
                <span v-else>
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </template>
              <!-- pagination -->
              <template
                slot="pagination-bottom"
                slot-scope="props"
              >
                <div class="d-flex justify-content-between flex-wrap">
                  <div class="d-flex align-items-center mb-0 mt-1">
                    <span class="text-nowrap">
                      Showing 1 to
                    </span>
                    <b-form-select
                      v-model="pageLength"
                      :options="['3','5','10']"
                      class="mx-1"
                      @input="(value)=>props.perPageChanged({currentPerPage:value})"
                    />
                    <span class="text-nowrap"> of {{ props.total }} entries </span>
                  </div>
                  <div>
                    <b-pagination
                      :per-page="pageLength"
                      :total-rows="props.total"
                      :value="1"
                      align="right"
                      class="mt-1 mb-0"
                      first-number
                      last-number
                      next-class="next-item"
                      prev-class="prev-item"
                      @input="(value)=>props.pageChanged({currentPage:value})"
                    >
                      <template #prev-text>
                        <feather-icon
                          icon="ChevronLeftIcon"
                          size="18"
                        />
                      </template>
                      <template #next-text>
                        <feather-icon
                          icon="ChevronRightIcon"
                          size="18"
                        />
                      </template>
                    </b-pagination>
                  </div>
                </div>
              </template>
            </vue-good-table>
          </b-card>
        </b-overlay>
        <b-modal
          id="view-incubator-modal"
          title="View Details"
          hide-footer
        >

          <!--          promised compontent used here for logo url form s3 to show logo in incubator details modal-->
          <Promised
            v-if="filterIncubators[selectedRow] && filterIncubators[selectedRow].logo"
            :promise="getLinkFromS3(filterIncubators[selectedRow].logo)"
          >
            <template #pending>
              <b-card-img
                class="card-img"
                src="https://picsum.photos/600/300"
              />
            </template>
            <template #default="data">
              <b-aspect
                :style="`background: url(${data}) center center / contain no-repeat`"
                aspect="2"
              />
            </template>
            <template #rejected>
              <b-card-img
                class="card-img"
                src="https://picsum.photos/600/300"
              />
            </template>
          </Promised>
          <b-card-img
            v-else
            class="card-img w-100"
            src="https://picsum.photos/600/300"
          />
          <b-table
            v-if="filterIncubators[selectedRow]"
            :fields="['title','official_email','official_phone','url','city','state','industry',{key: 'startup_stage', label: 'Stage'},'description']"
            stacked
            bordered
            :items="[filterIncubators[selectedRow]]"
          >
            <template #cell(url)="data">
              <b-link
                :href="prependHttps(data.item.url)"
                target="_blank"
              >{{ data.item.url }}
              </b-link>
            </template>
          </b-table>
        </b-modal>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BAspect,
  BButton,
  BCard,
  BCardImg,
  BFormGroup,
  BFormInput,
  BFormRadioGroup,
  BFormSelect,
  BLink,
  BPagination,
  BTable,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import gql from 'graphql-tag'
import { Promised } from 'vue-promised'
import { mapGetters } from 'vuex'

export default {
  components: {
    BCardImg,
    BAspect,
    BFormRadioGroup,
    BCard,
    Promised,
    BLink,
    BTable,
    VueGoodTable,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BButton,
  },
  data() {
    return {
      pageLength: 5,
      showDismissibleAlert: true,
      dir: false,
      filter: null,
      association: null,
      columns: [
        {
          label: 'Incubator',
          field: 'title',
        },
        {
          label: 'Association',
          field(row) {
            if (!row.usersAssociationtablesByAssociatedOrg) return '-'
            const startup = { ...row.usersAssociationtablesByAssociatedOrg[0] }
            // Role === startup means the startup is associated with the incubator but not yet approved
            if (startup.role === 'startup') return 'Network'
            if (startup.role === 'requestedstartup') return 'Applied for association'
            if (!startup.role) return 'Unassociated'
            return startup.role
          },
          tdClass: 'text-capitalize',
          filterOptions: {
            enabled: true, // enable filter for this column
            customFilter: true, // use custom filter component
            placeholder: '-- No Filter --', // placeholder for filter input
            filterDropdownItems: [
              {
                value: 'Unassociated',
                text: 'Unassociated',
              }, {
                value: 'Incubatee',
                text: 'Incubatee',
              }, {
                value: 'Pre-Incubatee',
                text: 'Pre-Incubatee',
              }, {
                value: 'Graduate',
                text: 'Graduate',
              }, {
                value: 'Network',
                text: 'Network',
              }], // dropdown (with selected values) instead of text input
          },
          firstSortType: 'asc',
        },
        {
          label: '',
          field: 'buttons',
          sortable: false,
        },
      ],
      incubators: [],
      mutationLoading: false,
      startupOrgs: [],
      searchTerm: '',
      selectedRow: null,

    }
  },
  computed: {
    ...mapGetters({
      selectedOrg: 'user/getSelectedOrgDetails',
      userDetails: 'user/getUserDetails',
    }),
    filterIncubators() {
      let { incubators } = this
      if (this.association) {
        incubators = incubators.filter(r => {
          if (this.association === 'associated') return r.usersAssociationtablesByAssociatedOrg.length > 0
          if (this.association === 'unassociated') return r.usersAssociationtablesByAssociatedOrg.length === 0
          return true
        })
      }

      return incubators
    },
  },
  methods: {
    sortByAssociation(incubators) {
      return incubators.sort((a, b) => {
        const aAssociated = !!a.usersAssociationtablesByAssociatedOrg.length
        const bAssociated = !!b.usersAssociationtablesByAssociatedOrg.length

        if (aAssociated && !bAssociated) {
          return -1
        }
        if (!aAssociated && bAssociated) {
          return 1
        }
        return 0
      })
    },
    // Insert into entry with incubator_id and organization_id into users_associationtables table with graphql mutate
    joinIncubator(incubatorId) {
      const incubatorName = this.incubators.find(incubator => incubator.id === incubatorId).title
      this.$bvModal.msgBoxConfirm(`Are you sure you want to join ${incubatorName}'s network?`, {
        title: 'Join Incubator',
        okVariant: 'success',
        okTitle: 'Yes',
        cancelTitle: 'No',
        centered: true,
      })
        .then(value => {
          if (value) {
            this.mutationLoading = true
            const mutation = gql`
        mutation {
          insert_users_associationtable_one(
            object: {
              organization_id: ${this.selectedOrg.orgId},
              incubator_id: ${incubatorId},
              role: "requestedstartup",
            }
          ) {
            id
          }
        }
      `
            this.$apollo.mutate({
              mutation,
            })
              .then(() => {
                this.showAlert('Successfully joined incubator\'s network.', 'success')
                this.mutationLoading = false
                this.$apollo.queries.incubators.refetch()
              })
              .catch(() => {
                this.showAlert('Failed to join incubator\'s network. Please try again later.', 'danger')
                this.mutationLoading = false
              })
          }
        })
    },
    getSubdomain() {
      const urlPrefix = window.location.host.split('.')[0]
      return (urlPrefix.startsWith('localhost') || urlPrefix.toLowerCase() === 'starworks' || urlPrefix.toLowerCase() === 'test' || urlPrefix.toLowerCase() === 'demo') ? '.*' : urlPrefix
    },
  },
  apollo: {
    incubators: {
      query() {
        const subdomain = this.getSubdomain()
        return gql`
          query Incubators {
            users_organizationtable(where: {type: {_ilike: "incubator"}, domain: {_regex: "${subdomain}"}}) {
              id
              title
              official_email
              official_phone
              logo
              url
              city
              state
              industry
              startup_stage
              description
              usersAssociationtablesByAssociatedOrg(where: {organization_id: {_eq: ${this.selectedOrg.orgId} }, role: {_in: ["startup", "pre-incubatee", "incubatee", "graduate","requestedstartup"]} })  {
                id
                role
                status
              }
            }
          }`
      },
      update(data) {
        const incubators = data.users_organizationtable

        return this.sortByAssociation(incubators)
      },
    },

  },
}
</script>
